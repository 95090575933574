<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Tulis Baru
            </div>
          </template>

          <v-form
            v-model="disable_state"
            @submit.prevent="setPost"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="title"
                    :rules="titleRules"
                    label="Judul"
                    class="purple-input"
                    prepend-icon="mdi-pencil-plus"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-select
                    v-model="status"
                    :items="status_items"
                    :rules="categoryRules"
                    class="purple-input"
                    prepend-icon="mdi-format-list-checks"
                    label="Pilih Status"
                    item-text="name"
                    item-value="id"
                  >
                    <!-- Template for render data when the select is expanded -->
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <!-- Divider and Header-->
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <!-- Normal item -->
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name" />
                          <v-list-item-subtitle
                            v-html="
                              data.item.parent ? data.item.parent.name : ''
                            "
                          />
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-file-input
                    v-model="file1"
                    :state="Boolean(file1)"
                    :loading="upload_state"
                    :disabled="disable_state"
                    counter
                    show-size
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    placeholder="Unggah Gambar Thumbnail"
                    @change="uploadImage"
                  />
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="post"
                    :rules="postRules"
                    class="purple-input"
                    label="Post Teks"
                    prepend-icon="mdi-square-edit-outline"
                    auto-grow
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    :disabled="!disable_state"
                    @click="setPost"
                  >
                    POSTING
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')

  export default {
    name: 'CreateNews',
    data () {
      return {
        title: '',
        thumbnail_id: 4,
        cover_id: 4,
        post: '',
        community_id: 1,
        sorting: 1,
        status: null,
        type: '',
        status_items: ['open', 'close'],
        file1: null,
        upload_state: false,
        disable_state: false,
        category_id: null,
        selected: null,
        titleRules: [value => value !== '' || 'Judul wajib diisi'],
        categoryRules: [value => value !== '' || 'Kategori wajib diisi'],
        postRules: [
          value => value !== '' || 'post wajib diisi',
          value => value.length >= 4 || 'password minimal 4 carakter',
        ],
      }
    },
    mounted () {
      this.getCategories()
    },
    methods: {
      setPost () {
        this.disable_state = true
        axios
          .post('/v1/forum/thread', {
            title: this.title,
            thumbnail_id: this.image_id,
            cover_id: this.cover_id,
            post: this.post,
            status: this.status,
            type: 'Forum',
            category_id: this.category_id,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.disable_state = false
              this.cover_id = response.data.data.id
              this.$router.push({ name: 'Forum' })
              this.$toast.success('Berhasil Di Buat', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              console.log(response.data.meta.message)
            }
          // console.log("image upload response > ", response.data);
          })
      },
      // upload (event) {
      //   this.upload_state = true
      //   console.log(event.target.files[0])
      // },
      // uploadImage (event) {
      //   if (this.file1 === null) {
      //     this.upload_state = false
      //     this.disable_state = false
      //     return 1
      //   }
      //   const URL = '/v1/media'
      //   this.upload_state = true
      //   const data = new FormData()
      //   data.append(
      //     'path',
      //     'image/news/community/' + this.$store.state.user.community_id,
      //   )
      //   data.append('file', this.file1)
      //   const config = {
      //     header: {
      //       'Content-Type': 'image/png',
      //     },
      //   }

      //   axios.post(URL, data, config).then(response => {
      //     if (response.data.meta.status) {
      //       this.upload_state = false
      //       this.disable_state = false
      //       this.cover_id = response.data.data.id
      //     } else {
      //       this.upload_state = false
      //       this.disable_state = false
      //     }
      //   })
      // },
      getCategories () {
        axios
          .get('/v1/forum/thread/category?entities=parent,childs')
          .then(response => {
            if (response.data.meta.status) {
              this.status = ['open', 'close']
            } else {
              console.log(response.data.meta.message)
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
